import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require ('../../node_modules/slicknav/jquery.slicknav.js');
require ('../../node_modules/lightbox2/dist/js/lightbox');
require('jquery-aniview');

jQuery.noConflict();

jQuery(document).ready(function(){
	
	jQuery('body img').addClass('img-fluid');
	
	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});
	
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});
	
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});

	//Slicknav START

	jQuery("#menu-top-primary").slicknav({
		label: '',
		closeOnClick: true
	});
	jQuery('.slicknav_menu').prepend('<a href="' + mainRootUrl + '"><img src="' + mainThemeUrl + '/assets/media_and_docs/logo.png" id="mob-logo" alt="Logo" width="380" height="87"></a>');

	if(jQuery('#wpadminbar').is(":visible")) {
		jQuery('#top-bar').addClass('main-wp-bar');
	} else jQuery('#top-bar').removeClass('main-wp-bar');

	//Slicknav STOP

	//StickyNav START

	var stickyNavTop = 140;

	// our function that decides weather the navigation bar should have "fixed" css position or not.
	var stickyNav = function(){
		var mainScrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (mainScrollTop > stickyNavTop) {
			jQuery('#top-bar').addClass('main-nav-top-sticky');
		} else {
			jQuery('#top-bar').removeClass('main-nav-top-sticky');
		}
	};

	jQuery(window).scroll(function() {
		stickyNav();
	});

	//StickyNav STOP

	//Label Contact Form 7 START

	jQuery('.wpcf7-form').addClass('form-one');

	jQuery('.wpcf7-form .form-group .wpcf7-form-control-wrap').each(function () {
		var thisC7W = jQuery(this);
		thisC7W.parent().prepend(thisC7W.find('.wpcf7-form-control'));
		thisC7W.parent().prepend(thisC7W.parent().find('label'));
	});

	jQuery('.wpcf7-form .form-check').each(function () {
		var thisC7C = jQuery(this);
		thisC7C.prepend(thisC7C.find('#check-header-one'));
		thisC7C.find('.form-check-label').append('<div class="main-star"></div>');
	});

	jQuery('.wpcf7-form textarea').removeAttr('size').removeAttr('rows').removeAttr('cols');
	jQuery('.wpcf7-form .form-control').removeAttr('size');
	jQuery('.wpcf7-form label').unwrap("p");
	jQuery('.wpcf7-form .form-group button').unwrap("p").addClass("main-btn-one");
	jQuery('.wpcf7-form .form-group button').append('<div class="btn-title">Wyślij</div><div class="btn-decor"></div>');

	jQuery('.form-one label').click(function() {
		jQuery(this).siblings('input, textarea').focus();
	});
	jQuery('.form-one .form-control').focus(function() {
		jQuery(this).siblings('label').hide();
	});
	jQuery('.form-one .form-control').blur(function() {
		var $thisFc = jQuery(this);
		if ($thisFc.val().length == 0)
			jQuery(this).siblings('label').show();
	});

	jQuery('.form-one').each(function () {
		var cf7o = jQuery(this);
		cf7o.prepend(cf7o.find('.wpcf7-response-output'));
		cf7o.find('.wpcf7-response-output').css('margin', '0 0 2rem 0');
	});

	//Label Contact Form 7 STOP

	jQuery('#menu-top-primary > .current_page_item > a, #menu-top-primary > .current_page_ancestor > a').addClass('active');
	jQuery('.box-type-two h3').addClass('h3');
	jQuery('.box-type-two ul').addClass('list-ctna-full');

	jQuery('.aniview').AniView({
		animateClass: 'animate__animated', // for v3 or 'animate__animated' for v4
		animateThreshold: 10,
		scrollPollInterval: 10
	});

	jQuery('.button-one-up').each(function() {
		var thisBtnOneUp = jQuery(this);
		thisBtnOneUp.html('<div class="btn-title">' + thisBtnOneUp.text() + '</div><div class="btn-decor"></div>')
	});
	
	mainResize();
	
});

window.onload = function () {
	jQuery('#main-loader').remove();
};

jQuery(window).resize(function() {
	mainResize();
});


//Funkcje

function mainResize() {
	var wwidth = jQuery(window).width();
    if (wwidth <= 767) {
		
	} else {
		
	}
}

function placementObjectBefore(element, elementBefore) {
	jQuery(elementBefore).before(jQuery(element));
}

function placementObjectAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}